<template>
  <div class="home">
    <Header />
    <div class="main">
      <Sidenav />
      <div class="home_router">
        <router-view />
      </div>
    </div>
    <!-- <el-dialog
            title="个人资料"
            :visible.sync="dialogVisible"
            width="30%"
    >
     <div class="letrs">
       <div class="flex">
         <div>
           手机号：
         </div>
       <div>{{userInfo.phone}}</div>
       </div>
       <div class="flex">
         <div>
           绑定角色：
         </div>
         <div>{{userInfo.group_name}}</div>
       </div>
       <div class="flex">
         <div>
           昵称：
         </div>
         <div>{{userInfo.nick_name}}</div>
       </div>
       <div class="flex">
         <div>
           绑定微信：
         </div>
         <div style="width: 50px">{{userInfo.is_bind_wx===1?'是':'否'}}</div>
         <div v-if="userInfo.is_bind_wx==0" style="color: #3a8ee6;cursor: pointer" @click="bindWxs">绑定</div>
         <div v-if="userInfo.is_bind_wx==1" style="color: #3a8ee6;cursor: pointer" @click="deteleBindInfo">解绑</div>
       </div>
       <img v-if="qr_code" :src="qr_code" alt="" width="200px" >
     </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog> -->
  </div>
</template>
<script>
import Header from '@/components/Header';
import Sidenav from '@/components/Sidenav';
export default {
  data() {
    return {
      dialogVisible:false,
      userInfo:'',
      bindWx:false,
      qr_code:'',
    };
  },
  components: {
    Header,
    Sidenav
  },
  // methods: {
  //   flag(e){
  //     this.userInfo=window.utils.storage.getter("userInfo")
  //     this.dialogVisible=e
  //   },
  //   deteleBindInfo(){
  //     this.axios.post('/store/Shopadmin/deteleBindInfo').then(res=>{
  //       this.$message.success('解绑成功！');
  //               this.axios.post('/store/shop/getUserInfo').then((res) => {
  //                 if(res.data.is_bind_wx === 0) {
  //                   this.userInfo={
  //                     ...this.userInfo,
  //                     is_bind_wx: 0
  //                   }
  //                   window.utils.storage.setter('userInfo', this.userInfo);
  //                 }
  //               })
  //     })
  //   },
  //   bindWxs(){
  //     this.bindWx=true
  //     this.axios.post('/store/shop/getBindQrcode').then(res=>{
  //       this.qr_code=res.data.qr_code
  //     })
  //     let timer = setInterval(() => {
  //       this.axios.post('/store/shop/getUserInfo').then((res) => {
  //        if(res.data.is_bind_wx === 1){
  //          this.bindWx=false
  //          this.userInfo={
  //            ...this.userInfo,
  //            is_bind_wx: 1
  //          }
  //          this.$message.success('绑定成功！');
  //          this.qr_code=''
  //          window.utils.storage.setter('userInfo', this.userInfo);
  //          clearInterval(timer)
  //        }
  //       })
  //     }, 2000)
  //   }
  // }
};
</script>
<style lang="scss" scoped>
.main {
  box-sizing: border-box;
  margin-top: 50px;
}
.home_router {
  margin-left: 200px;
  box-sizing: border-box;
  min-width: 1130px;
}
  .letrs{
    width: 80%;
    margin: 0 auto;
    .flex{
      &>div{
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
</style>

<template>
  <div class="sidenav-container">
    <div class="sidenavbox">
      <el-menu class="el-menu-vertical-demo sidenav"
               background-color="#121945"
               text-color="#c1c1c1"
               active-text-color="#fff"
               router
               :unique-opened="true"
               collapse-transition
               :default-active="onRoutes">
        <el-menu-item index="/home/workbenchNew">
          <img :src="nav1"
               alt
               style="width:16px" />
          <span style="margin-left:10px">首页</span>
        </el-menu-item>
        <div v-for="menu1 in menuList"
             :key="menu1.module_id">
          <el-submenu :index="menu1.index">
            <template slot="title">
              <img :src="menu1.icon"
                   alt
                   style="width:16px" />
              <span style="margin-left:10px">{{menu1.module_name}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-for="menu2 in menu1.list"
                            :key="menu2.module_id"
                            :index="menu2.path">{{menu2.module_name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
import guideList from '../service/guide.js';
import home from '@/api/home.js';
export default {
  data() {
    return {
      menuList: [],
      module_id_array: '',
      currentMenu: window.location.hash.split('#')[1] || '',
      nav1: require('@/assets/nav0.svg'),
    };
  },
  created() {
  },
  mounted() {
    this.init()
  },
  computed: {
    onRoutes() {
      const route = this.$route
      const { path } = route
      return path
    }
  },
  methods: {
    init() { // 获取权限
      let userInfo = window.utils.storage.getter('userInfo');
      home.getModuleList({ admin_id: userInfo.admin_id }).then((res) => {
        let module_id_array = res.data.info.module_id_array ? res.data.info.module_id_array.split(',') : [];
        if (res.data.info.is_create == 1) {
          for (let i = 1; i <= 999; i++) {
            module_id_array.push(i.toString());
          }
        }
        this.module_id_array = module_id_array
        window.utils.storage.setter('module_id_array', module_id_array);
        this.getList();
      })
    },
    //获取该角色的路由菜单
    getList() {
      let menuList = [];
      guideList.forEach((ele1) => {
        let index1 = this.module_id_array.findIndex((id) => {
          return id == ele1.module_id;
        });
        if (index1 != -1 || this.module_id_array.includes('999')) {
          let obj = JSON.parse(JSON.stringify(ele1));
          obj.list = [];
          ele1.list.forEach((ele2) => {
            let index2 = this.module_id_array.findIndex((id) => {
              return id == ele2.module_id;
            });
            if (index2 != -1 || this.module_id_array.includes('999')) {
              obj.list.push(ele2);
            }
          });
          menuList.push(obj);
        }
      });
      this.menuList = menuList;
    }
  }
};
</script>
<style lang="scss" scoped>
.sidenav-container {
  position: fixed;
  width: 203px;
  z-index: 1000;
  // height: 100vh;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.sidenavbox {
  width: 220px;
  height: calc(100vh - 60px);
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  margin-top: 50px;
}
.sidenav {
  width: 200px;
  height: 100vh;
  text-align: left;
}
.navtitle {
  width: 200px;
  background: #121945;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  box-sizing: border-box;
}
</style>
<style lang="scss" scoped>
.el-menu-item.is-active {
  background-color: #4974f5 !important;
}
.el-menu-item:hover {
  background-color: #4974f5 !important;
}
</style>
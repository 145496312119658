<template>
  <div class="myheader">
    <div class="my_first" style="font-size: 12px; line-height: 60px">
      <div>
        <img src="@/assets/logo1.png" alt />
      </div>
    </div>
    <div class="userbox">
      <!-- <el-dropdown placement="bottom">
        
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="personalData">个人资料</el-dropdown-item>
          <el-dropdown-item @click.native="tologOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>  -->
      <el-popover placement="bottom" width="280" trigger="hover">
        <div class="pop_box">
          <div class="user_head">
            <div class="head_left">
              <el-avatar
                :src="head_photo"
                style="width: 30px; height: 30px"
              ></el-avatar>
              <span class="head_name">你好，{{ username }}</span>
            </div>
            <div class="head_right" @click="toUserInfo">
              <img src="../assets/userEdit.png" alt="" />
              <span style="margin-left: 3px; cursor: pointer">编辑</span>
            </div>
          </div>
          <div class="main_info">
            <div class="margin_t">
              <img
                src="../assets/userPhone.png"
                alt=""
                style="margin-right: 11px"
              />
              <span>电话：</span>
              <span class="info_text">{{ phone }}</span>
            </div>
            <div class="margin_t">
              <img
                src="../assets/userPosition.png"
                alt=""
                style="margin-right: 11px"
              />
              <span>岗位：</span>
              <span class="info_text">{{ group_name }}</span>
            </div>
            <div class="margin_t">
              <img
                src="../assets/userNicname.png"
                alt=""
                style="margin-right: 11px"
              />
              <span>店铺：</span>
              <span class="info_text">{{ shop_name }}</span>
            </div>
          </div>
          <div class="user_bottom">
            <div @click="tologOut" style="cursor: pointer">
              <img
                src="../assets/user_out.png"
                alt=""
                style="margin-right: 5px"
              />
              <span>退出系统</span>
            </div>
          </div>
        </div>

        <div class="el-dropdown-link" slot="reference">
          <el-avatar
            :src="head_photo"
            size="small"
          ></el-avatar>
          <span class="user_name">你好，{{ username }}</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import head_photo from "../assets/head_photo.svg"
export default {
  data() {
    return {
      username: window.utils.storage.getter("userInfo").nick_name || "",
      shop_name: window.utils.storage.getter("userInfo").shop_name || "",
      phone: window.utils.storage.getter("userInfo").phone || "",
      group_name: window.utils.storage.getter("userInfo").group_name || "",
      head_photo:head_photo,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  methods: {
    tologOut() {
      window.utils.storage.remove("userInfo");
      window.utils.storage.remove("headerToken");
      window.utils.storage.remove("exposedToken");
      this.$router.push("/");
    },
    toUserInfo() {
      if (this.module_id_array.includes("24")) {
        this.$router.push("/person/personInfo");
      }else{
        this.$notify.error({
          title: '暂无权限，请联系管理员',
        });
      }
      
    },
    // personalData(){
    //   this.$emit('flag',true)
    // }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.myheader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #ffffff;
  font-size: 16px;
  z-index: 1000;
  box-shadow: 0px 1px 2px 0px rgba(0, 21, 41, 0.12);
  display: flex;
  justify-content: space-between;
  // padding: 0 26px 0 30px;
  box-sizing: border-box;
  .my_first {
    width: 200px;
    text-align: center;
    line-height: 50px;
    background-color: rgb(18, 25, 69);
  }
  .userbox {
    display: flex;
    align-items: center;
  }
  .user_name {
    margin-left: 8px;
    height: 23px;
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c;
  }
  .el-dropdown-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 26px;
  }
}
.pop_box {
  .user_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    .head_left {
      display: flex;
    }
    .head_name {
      font-size: 14px;
      color: #333333;
      margin-left: 4px;
      line-height: 30px;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .head_right {
      min-width: 45px;
      font-size: 13px;
      color: #4974f5;
    }
  }
  .main_info {
    font-size: 13px;
    color: #333333;
    padding-bottom: 13px;
    margin-bottom: 11px;
    border-bottom: 2px solid #f3f3f3;
    .margin_t {
      margin-top: 13px;
      display: flex;
      align-items: center;
      .info_text {
        color: #666666;
        margin-left: 6px;
        display: inline-block;
      }
    }
  }
  .user_bottom {
    font-size: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
